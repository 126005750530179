import { EMPTY_CHANGE_KEY_DATA_SEARCH_CRITERIA, type ChangeKeyDataSearchCriteria } from "domain/entity/ChangeKeyData/ChangeKeyDataSearchCriteria";
import { DEFAULT_DATE_RANGE } from "domain/repository/apiUtil";
import moment from "moment";
import { useChangeKeyDataVM } from "presentation/hook/ChangeKeyData/useChangeKeyDataVM";
import { useChangeKeyDataTracked } from "presentation/store/ChangeKeyData/ChangeKeyDataProvider";
import { memo, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import ChangeKeyDataSearchPanel from "./LeftPanel/ChangeKeyDataSearchPanel";
import ChangeKeyDataRightPanel from "./RightPanel/ChangeKeyDataRightPanel";


const ChangeKeyDataMaintenance: React.FC = () => {
    const [changeKeyDataState] = useChangeKeyDataTracked();
    const { isShowCriteriaPanel } = changeKeyDataState;
    const changeKeyDataVM = useChangeKeyDataVM();
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        const initialScreen = async () => {
            try {
                const cacheSearchCriteria = localStorage.getItem("ChangeKeyDataSearchCriteria");
                let newSearchCriteria = { ...EMPTY_CHANGE_KEY_DATA_SEARCH_CRITERIA }
                newSearchCriteria = {
                    ...newSearchCriteria,
                    createdDateFrom: moment().subtract(DEFAULT_DATE_RANGE, "days").startOf("day").toDate(),
                    createdDateTo: moment().endOf("day").toDate(),
                };
                const results = await Promise.allSettled([
                    changeKeyDataVM.loadDropdownOption(),
                    cacheSearchCriteria && changeKeyDataVM.getChangeKeyDatas(JSON.parse(cacheSearchCriteria) as ChangeKeyDataSearchCriteria),
                    !cacheSearchCriteria && changeKeyDataVM.getChangeKeyDatas(newSearchCriteria),
                ]);
                results?.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {

                    } else if (index === 1 && result.status === 'rejected') {

                    }
                })
            } catch (error) {
            }
        }
        initialScreen().then((data) => {
            setIsLoading(false)
        }).catch(error => {
            setIsLoading(false)
        });
    }, [changeKeyDataVM])



    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;

    return <>
        <div className={`main-comp-wrapper${isShowCriteriaPanel ? '' : ' im-hide-side-form-draggable'}`}>


            <SliderPanel
                isOpen={true}
                draggable={false}
                leftSectionWidth={isShowCriteriaPanel ? "25%" : "0%"}
                rightSectionWidth={isShowCriteriaPanel ? "75%" : "100%"}
                leftChildren={<ChangeKeyDataSearchPanel />}
                rightChildren={<ChangeKeyDataRightPanel />}
            />
        </div>
    </>
}

export default memo(ChangeKeyDataMaintenance);