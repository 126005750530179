import { CustomCellRendererProps } from "ag-grid-react";
import { calculateStateColor, defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { cellRenderWithColorAndCircle } from "presentation/view/components/TableWrapper/TableCellRender";
import { ChangeKeyDataConstant } from "./ChangeKeyDataConstant";

const CONTAINER_CONSTANT = ChangeKeyDataConstant.Charge;
let dateFieldList: string[] = [];
let dateTimeFieldList: string[] = [];
export const INITIAL_CHANGE_KEY_DATA_COL_DEF: any[] = [
    {
        headerName: CONTAINER_CONSTANT.PROCESS_STATUS,
        field: 'processStatus',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        pinned: "left",
        checkboxSelection: true,
        headerCheckboxSelection: true,
        width: 200,
    },
    {
        headerName: CONTAINER_CONSTANT.CNTR_NO,
        field: 'cntrNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.HANDING_TML,
        field: 'handlingTerminal',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.VESSEL_CODE,
        field: 'vesselCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.VOYAGE_CODE,
        field: 'voyageCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.NEW_VESSEL,
        field: 'chargeInfo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 320,
    },
    {
        headerName: CONTAINER_CONSTANT.OLD_VESSEL,
        field: 'oldChargeInfo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 320,
    },
    {
        headerName: CONTAINER_CONSTANT.BILL_CODE,
        field: 'billCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 120,
    },
    {
        headerName: CONTAINER_CONSTANT.FIRST_IN_TML,
        field: 'firstInTmlDatetime',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
        dataType: "dateTime"
    },
    {
        headerName: CONTAINER_CONSTANT.OLD_CNTR_NO,
        field: 'refCntrNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
    },
    {
        headerName: CONTAINER_CONSTANT.OLD_BILL_CODE,
        field: 'refBillCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.OLD_FIRST_IN_TML,
        field: 'refFirstInTmlDatetime',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 250,
        dataType: "dateTime"
    },
    {
        headerName: CONTAINER_CONSTANT.CREATED_DATE,
        field: 'createdDateTime',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
        dataType: "date"
    },
    {
        headerName: CONTAINER_CONSTANT.NEW_ETB,
        field: 'etb',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
        dataType: "dateTime"
    },
    {
        headerName: CONTAINER_CONSTANT.OLD_ETB,
        field: 'refEtb',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
        dataType: "dateTime"
    },
    {
        headerName: CONTAINER_CONSTANT.NEW_SERVICE_CODE,
        field: 'serviceCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: CONTAINER_CONSTANT.OLD_SERVICE_CODE,
        field: 'refServiceCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: CONTAINER_CONSTANT.COKD_TYPE,
        field: 'cokdType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.COKD_OPS_DATE,
        field: 'opsDatetime',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
        dataType: "dateTime"
    },
    {
        headerName: CONTAINER_CONSTANT.COKD_SUB_TYPE,
        field: 'cokdSubType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: CONTAINER_CONSTANT.CNTR_SIZE,
        field: 'cntrSize',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 130,
    },
    {
        headerName: CONTAINER_CONSTANT.OLD_CNTR_SIZE,
        field: 'refCntrSize',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: CONTAINER_CONSTANT.NEW_FINAL_OUT_DATE_TIME,
        field: 'finalOutTmlDatetime',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 230,
        dataType: "dateTime"
    },
    {
        headerName: CONTAINER_CONSTANT.OLD_FINAL_OUT_DATE_TIME,
        field: 'refFinalOutTmlDatetime',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 250,
        dataType: "dateTime"
    },
    {
        headerName: CONTAINER_CONSTANT.NEW_TRANS_STATUS,
        field: 'cntrTspStatus',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 230,
    },
    {
        headerName: CONTAINER_CONSTANT.OLD_TRANS_STATUS,
        field: 'refCntrTspStatus',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 250,
    },
    {
        headerName: CONTAINER_CONSTANT.NEW_OOG,
        field: 'oog',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 130,
    },
    {
        headerName: CONTAINER_CONSTANT.OLD_OOG,
        field: 'refOog',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 130,
    },
    {
        headerName: CONTAINER_CONSTANT.NEW_EMPTY_IND,
        field: 'emptyIndicator',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: CONTAINER_CONSTANT.OLD_EMPTY_IND,
        field: 'refEmptyIndicator',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: CONTAINER_CONSTANT.NEW_IMO,
        field: 'imo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 130,
    },
    {
        headerName: CONTAINER_CONSTANT.OLD_IMO,
        field: 'refImo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 130,
    },

]?.map((col, index) => {
    const cellRenderers: { [key: string]: ((params: CustomCellRendererProps) => {}) } = {};
    cellRenderers['processStatus'] = cellRenderWithColorAndCircle;
    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers);
}
);

export const transferRowData = (data: any[]) => {
    const externalFnctions: { [key: string]: ((fieldName: string, row: any) => {}) } = {};
    externalFnctions['processStatus'] = calculateStateColor;
    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions);
}